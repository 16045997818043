import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { accessRulesSettingsMessages } from '../../locales'

export function CreateAccessRuleButton({ date, onClick }: { date: Date; onClick: (date: Date) => void }) {
  const { formatMessage } = useLocales()
  const permissions = useNavigationPermissionsContext()

  return permissions?.canAdministrateShiftsAccessRules ? (
    <Button onClick={() => onClick(date)} icon="VMSWeb-edit" data-test="btn-create-access-rule">
      {formatMessage(accessRulesSettingsMessages.createAccessRule)}
    </Button>
  ) : null
}
