import { useState } from 'react'
import type { ToggleButtonProps } from '@sevenrooms/react-components'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { ToggleButton } from '@sevenrooms/react-components/components/ToggleButton'
import { ToggleButtonGroup } from '@sevenrooms/react-components/components/ToggleButtonGroup'
import { AccessRuleRange, AccessRuleView } from '../ActionButtons/AccessRulesActionButtons'

interface FilledToggleButtonProps extends ToggleButtonProps {
  'data-test'?: string
}

function FilledToggleButton({ value, children, ...props }: FilledToggleButtonProps) {
  return (
    <ToggleButton
      value={value}
      data-test={props['data-test']}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.primary',
        '&.Mui-selected': {
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          '&:hover': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            cursor: 'default',
          },
        },
        '&.MuiToggleButtonGroup-lastButton': {
          m: 0,
        },
      }}
    >
      {children}
    </ToggleButton>
  )
}

interface ActionsBarProps {
  handlers: {
    setView: (view: AccessRuleView) => void
    setDate: (date: Date) => void
    setRange: (range: AccessRuleRange) => void
  }
}

export function ActionsBar({ handlers }: ActionsBarProps) {
  const [viewToggle, setViewToggle] = useState<AccessRuleView>(AccessRuleView.CALENDAR)
  const [rangeToggle, setRangeToggle] = useState<AccessRuleRange>(AccessRuleRange.WEEK)

  const handleViewToggle = (event: React.MouseEvent<HTMLElement>, newView: AccessRuleView) => {
    if (newView !== null) {
      setViewToggle(newView)
      handlers.setView(newView)
      if (newView === AccessRuleView.LIST && rangeToggle === AccessRuleRange.WEEK) {
        setRangeToggle(AccessRuleRange.OVERVIEW)
        handlers.setRange(AccessRuleRange.OVERVIEW)
      } else if (newView === AccessRuleView.CALENDAR && rangeToggle === AccessRuleRange.OVERVIEW) {
        setRangeToggle(AccessRuleRange.WEEK)
        handlers.setRange(AccessRuleRange.WEEK)
      }
    }
  }

  const handleRangeToggle = (event: React.MouseEvent<HTMLElement>, newRange: AccessRuleRange) => {
    if (newRange !== null) {
      setRangeToggle(newRange)
      handlers.setRange(newRange)
    }
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack direction="row" spacing={8}>
        <Button data-test="btn-date" variant="outlined">
          Date Picker
        </Button>
        <ToggleButtonGroup value={rangeToggle} data-test="day-week-switch" exclusive color="primary" onChange={handleRangeToggle}>
          {viewToggle === AccessRuleView.CALENDAR ? (
            <FilledToggleButton value={AccessRuleRange.WEEK} data-test="btn-week">
              Week
            </FilledToggleButton>
          ) : (
            <FilledToggleButton value={AccessRuleRange.OVERVIEW} data-test="btn-overview">
              Overview
            </FilledToggleButton>
          )}
          <FilledToggleButton value={AccessRuleRange.DAY} data-test="btn-day">
            Day
          </FilledToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <ToggleButtonGroup value={viewToggle} data-test="cal-list-switch" exclusive color="primary" onChange={handleViewToggle}>
        <FilledToggleButton value={AccessRuleView.CALENDAR} data-test="btn-cal">
          Calendar
        </FilledToggleButton>
        <FilledToggleButton value={AccessRuleView.LIST} data-test="btn-list">
          List
        </FilledToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}
