import { useCallback } from 'react'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { CreateAccessRuleButton } from './CreateAccessRuleButton'
import { ViewAccessLogButton } from './ViewAccessLogButton'
import { ViewReservationWidgetButton } from './ViewReservationWidgetButton'

export enum AccessRuleView {
  LIST = 'list',
  CALENDAR = 'calendar',
}

export enum AccessRuleRange {
  DAY = 'day',
  WEEK = 'week',
  OVERVIEW = 'overview',
}

interface WeekViewActionButtonsProps {
  view: AccessRuleView
  date: Date
  range: AccessRuleRange
}

export function AccessRulesActionButtons({ view, range, date }: WeekViewActionButtonsProps) {
  const onClick = useCallback(
    (date: Date) => {
      switch (view) {
        case AccessRuleView.LIST:
          if (range === AccessRuleRange.DAY) {
            // eslint-disable-next-line no-console
            console.log('Create access rule for day', date)
          }
          // eslint-disable-next-line no-console
          console.log('Create access rule for day', date)
          break
        case AccessRuleView.CALENDAR:
          // eslint-disable-next-line no-console
          console.log('Create access rule for calendar', date)
          break
        default:
          // eslint-disable-next-line no-console
          console.error('Unsupported view', view)
          break
      }
    },
    [view, range]
  )
  return (
    <HStack spacing="s">
      <ViewAccessLogButton />
      <ViewReservationWidgetButton />
      <CreateAccessRuleButton date={date} onClick={onClick} />
    </HStack>
  )
}
