import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesSettingsMessages } from '../../locales'

export function ViewAccessLogButton() {
  const { formatMessage } = useLocales()
  const { venueUrlKey } = useAppContext()
  const permissions = useNavigationPermissionsContext()

  const activityLogLink = `${window.location.origin}/manager/${venueUrlKey}/activitylog?selectedCategory=ACCESS`

  return permissions?.canViewReportingFull ? (
    <Button size="m" variant="secondary" onClick={() => window.location.assign(activityLogLink)} data-test="btn-view-activity-log">
      {formatMessage(accessRulesSettingsMessages.viewAccessLog)}
    </Button>
  ) : null
}
