import { toZonedTime } from 'date-fns-tz'
import { useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Link } from '@sevenrooms/react-components/components/Link'
import { ThemeProvider, vmsTheme } from '@sevenrooms/react-components/components/ThemeProvider'
import { AccessRuleRange, AccessRulesActionButtons, AccessRuleView } from '../../components/ActionButtons/AccessRulesActionButtons'
import { ActionsBar } from '../../components/ActionsBar/ActionsBar'
import { accessRulesSettingsMessages } from '../../locales'

export function AccessRulesSettings() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const { venueTimezone } = useAppContext()
  const [currentView, setCurrentView] = useState<AccessRuleView>(AccessRuleView.CALENDAR)
  const [currentDate, setCurrentDate] = useState<Date>(toZonedTime(new Date(Date.now()), venueTimezone))
  const [currentRange, setCurrentRange] = useState<AccessRuleRange>(AccessRuleRange.WEEK)

  if (!venue) {
    return null
  }

  return (
    <ThemeProvider theme={vmsTheme}>
      <SettingsPageMeta title={formatMessage(accessRulesSettingsMessages.title)} />
      <SettingsPageContent
        secondHeaderMaxWidth="100%"
        secondHeaderTextMaxWidth="90%"
        title={formatMessage(accessRulesSettingsMessages.title)}
        description={formatMessage(accessRulesSettingsMessages.subtitle, {
          a: (chunks: string[]) => (
            <Link data-test="access-rules-settings-help" href="https://help.sevenrooms.com/hc/en-us/categories/360004583192-Availability">
              {chunks}
            </Link>
          ),
        })}
        actions={<AccessRulesActionButtons view={currentView} range={currentRange} date={currentDate} />}
      >
        <Flex backgroundColor="primaryBackground" p="lm" width="100%" height="100%" display="flex" flexDirection="column">
          <ActionsBar handlers={{ setView: setCurrentView, setDate: setCurrentDate, setRange: setCurrentRange }} />
        </Flex>
      </SettingsPageContent>
    </ThemeProvider>
  )
}
